/** 
 filename     : Admin CSS
 description  : CSS for admin panel login
*/
html,
body {
  height: 100%;
  max-height: 100%;
  background-color: #e2e2e2;
}

.navbar {
  z-index: 9;
}

.svg-icon {
  font-size: 16px;
  margin-bottom: 3px;
  margin-right: 3px;
  color: #2d3236;
}

#sidebar {
  border-right: 1px solid #ccc;
  padding: 0px;
}
#sidebar #sidebar-wrapper {
  height: calc(100vh - 55px);
  border-right: 0px !important;
}

/* Style to reverse the caret icon from pointing downwards to upwards */
.caret.caret-up {
  border-top-width: 0;
  border-bottom: 4px solid #fff;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

/*****************************
    ADMIN LOGIN FORM
******************************/
#login {
  background: linear-gradient(to bottom, #00aff0 30%, #e2e2e2 0) repeat-x #e2e2e2;
  width: 100%;
  display: inline-table;
  overflow-x: visible;
}
#login .bg-admin-login {
  background: url(../images/bg_login.png) center top no-repeat;
  min-height: 538px;
  background-size: contain;
}
#login .login-box {
  background: #fff;
  border: 3px solid #efefef;
  border-radius: 10px 10px;
}
#login .heading {
  margin: 10px;
  font: bold 16px Arial;
  color: #818181;
}